@import '../../Assets/SASS/main.scss';

.gallery {
  position: relative;
  top: 123px;
  padding: 1.5% 1% 4%;
  font-family: 'poppins';
}

.title {
  font-size: 36px;
  color: $navyBlue;
}

.subText {
  color: $burntSienna;
  margin-bottom: 15px;
}

.galleryBody {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: $breakpoint-tablet) {
  .gallery {
    top: 98px;
  }
}

@media (max-width: $breakpoint-mobile) {
  .gallery {
    top: 98px;
  }
}