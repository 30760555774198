@import '../../Assets/SASS/main.scss';

.contact {
  position: relative;
  top: 123px;
  padding: 1.5% 1% 4%;
  font-family: 'poppins';
}

.title {
  font-size: 36px;
  color: $navyBlue;
}

.subText {
  color: $burntSienna;
  margin-bottom: 15px;
}

.contactDetails {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.contactContainer {
  display: flex;
}

.form {
  padding: 30px 40px;
  min-width: 280px;
  border: 2px solid $navyBlue;
  border-right: 0px;
  border-radius: 25px 0 0 25px;
}

.details {
  padding: 30px 40px;
  min-width: 280px;
  border: 2px solid $navyBlue;
  border-left: 0px;
  border-radius: 0 25px 25px 0;
  background-color: $navyBlue;
  color: #ffffff;
}

.detailsTitle {
  font-size: 26px;
  margin-bottom: 20px;
  color: $burntSienna;
}

.root {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

@media (max-width: $breakpoint-tablet) {
  .contact {
    top: 98px;
  }
}

@media (max-width: $breakpoint-mobile) {
  .contact {
    top: 98px;
  }
}

@media (max-width: 600px) {
  .contactContainer {
    flex-direction: column;
    align-items: center;
  }

  .form {
    width: 100%;
    border-right: 2px solid $navyBlue;
    border-radius: 6px;
    margin-bottom: 20px;
  }

  .details {
    width: 100%;
    border-right: 2px solid $navyBlue;
    border-radius: 6px;
    margin-bottom: 20px;
  }
}

@media (max-width: 382px) {
  .form {
    width: 278px;
    padding: 15px 10px;
  }

  .details {
    padding: 15px 10px;
    width: 278px;
  }
}