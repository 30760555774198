@import '../../Assets/SASS/main.scss';

.container {
  background-color: $navyBlue !important;
  position: fixed !important;
  z-index: 999;

  .logoContainer {
    padding: 0 10px;
    background-color: $white;
    border-radius: 8px;
    margin: 8px 0;

    >img {
      height: 100px;
    }
  }

  .logo {
    // height: 54px;
    cursor: pointer;
    border-radius: 4px;
    border-radius: 8px;
  }

  .shopBtn {
    color: white;
    text-decoration: none;
    background: #e47e52;
    padding: 6px 16px;
    border-radius: 6px;
    font-size: 20px;
  }

  @media (max-width: $breakpoint-tablet) {
    .logo {
      display: none;
    }

    .userName {
      display: flex !important;
    }
  }

  .logoMobile {
    height: 78px;
    margin: 10px 0;
    cursor: pointer;


    &>img {
      border-radius: 4px;
      border-radius: 8px;
      background-color: $white;
      padding: 4px;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    .logoMobile {
      display: none;
    }

    .userName {
      display: flex !important;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    .logoMobile {
      // height: 48px;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    .logoMobile {
      // height: 44px;
    }
  }
}
