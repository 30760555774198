@font-face {
  font-family: 'poppins';
  src: url('../Fonts/Poppins-Medium.ttf');
}
@font-face {
  font-family: 'poppins-light';
  src: url('../Fonts/Poppins-Light.ttf');
}

@font-face {
  font-family: 'poppins semibold';
  src: url('../Fonts/Poppins-SemiBold.ttf');
}
