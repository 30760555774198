@import '../../Assets/SASS/main.scss';

.home {
  position: relative;
  top: 123px;
  color: $navyBlue;
}

.homePageText {
  padding: 20px 30px;
  text-align: justify;
}

.css-dljw17 {
  margin-bottom: 50px !important;
}

h2 {
  color: $burntSienna;
  text-align: left;
}

@media (max-width: $breakpoint-tablet) {
  .home {
    top: 98px;
  }
}

@media (max-width: $breakpoint-mobile) {
  .home {
    top: 98px;
  }
}