@import '../../Assets/SASS/main.scss';

.bg {
  background-image: url('../../Assets/Images/world (1).svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top 20px;
  @media (max-width: $breakpoint-tablet) {
    background-size: auto;
  }
  z-index: -1;
}
.logoContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 20px;
  .logo {
    height: 80px;
    border-radius: 10px;
  }

  .Background {
    position: relative;
    margin-left: 2500px;
  }

  @media (max-width: $breakpoint-tablet) {
    .logo {
      height: 64px;
      border-radius: 8px;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    .logo {
      height: 52px;
      border-radius: 6px;
    }
  }
}
