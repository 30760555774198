* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'poppins';
}

body {
  min-height: 100vh;
}
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #4e4f50 #dfe9eb;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 6px;
  width: 6px;
}
*::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #dfe9eb;
}

*::-webkit-scrollbar-track:hover {
  background-color: #b8c0c2;
}

*::-webkit-scrollbar-track:active {
  background-color: #b8c0c2;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #4e4f50;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #4e4f50;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #0b1c48;
}

html {
  scroll-behavior: smooth;
}
