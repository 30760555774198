@import '../../Assets/SASS/main.scss';

.services {
  position: relative;
  top: 123px;
  padding: 1.5% 1% 4%;
  font-family: 'poppins';
}

.title {
  font-size: 36px;
  color: $navyBlue;
}

.subText {
  color: $burntSienna;
  margin-bottom: 15px;
}

.servicesBody {
  display: flex;
  flex: 100%;
}

.left {
  width: 280px;
  background: $navyBlue;
  color: $burntSienna;
  padding: 20px 15px;
  color: $white;
  font-size: 14px;
  text-align: left;
  height: 100%;
}

.right {
  width: 100%;
  height: 100%;
  padding: 0 0px 20px 15px;
}

.item {
  cursor: pointer;
  line-height: 2em;
  transition: 0.3s;

  &:hover {
    transform: scale(1.08);
  }
}

.currentPath {
  color: $burntSienna !important;
}

.serviceHeader {
  font-size: 26px;
  color: $burntSienna;
}

.serviceBody {
  display: flex;
}

.serviceText {
  color: $navyBlue;
  text-align: justify;
}

.serviceRightImg {
  height: auto;
}

h4 {
  color: $burntSienna;
}

.mainImg {
  width: 380px;
  float: right;
  margin-left: 20px;
  margin-bottom: 20px;
}

.Img {
  width: 100%;
}

hr {
  opacity: 0.1;
}

.video {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: $breakpoint-tablet) {
  .services {
    top: 98px;
  }

  .servicesBody {
    flex-direction: column;
  }

  .left {
    width: 100%;
  }

  .item {
    line-height: 1.8em;

    &:hover {
      transform: none;
    }
  }

  .serviceHeader {
    margin-top: 25px;
  }

  .right {
    padding: 0 10px 20px 10px;
  }

  .mainImg {
    width: 100%;
    float: unset;
    margin-left: unset;
    margin-top: 15px;
  }
}

@media (max-width: $breakpoint-mobile) {
  .services {
    top: 98px;
  }
}