@import '../../Assets/SASS/main.scss';

.slider-wrapper {
  max-height: 90vh !important;
}

.slide {
  filter: blur(3px);
}
.selected {
  filter: unset !important;
}

.arrow {
  background-color: aqua;
  height: 50px;
  width: 50px;
  position: absolute;
  z-index: 99999;
}
