@import '../../Assets/SASS/main.scss';

.about {
  position: relative;
  top: 123px;
  padding: 1.5% 1% 4%;
  font-family: 'poppins';
}

.title {
  font-size: 36px;
  color: $navyBlue;
}

.subText {
  color: $burntSienna;
  margin-bottom: 15px;
}

.Text {
  color: $navyBlue;
  text-align: justify;
}

ul {
  padding-left: 40px;
}

@media (max-width: $breakpoint-tablet) {
  .about {
    top: 98px;
  }
}

@media (max-width: $breakpoint-mobile) {
  .about {
    top: 98px;
  }
}